<template>
	<div>
		<el-breadcrumb separator-class="el-icon-arrow-right">
			<el-breadcrumb-item :to="{ path: '/welcome' }">首页</el-breadcrumb-item>
			<el-breadcrumb-item>联系GIGI</el-breadcrumb-item>
		</el-breadcrumb>
		<el-card>
			<div class="image-wrap">
				<img src="@/assets/img/contact.png" alt="">
			</div>
			<ul>
				<li>电话：+1 2817307109</li>
				<li>邮箱：gigiteam@houstonbesthome.com</li>
				<li>微信号：MgtzdL</li>
			</ul>
		</el-card>
		<el-card>
			<p>发送邮件给我们</p>
			<el-form :model="contactForm" :rules="contactFormRules" ref="contactFormRef"
				label-width="100px">
				<el-row>
					<el-col :span="11">
						<el-form-item label="姓名" prop="fullname">
							<el-input v-model="contactForm.fullname" disabled></el-input>
						</el-form-item>
					</el-col>
				</el-row>
				<el-row>
					<el-col :span="11">
						<el-form-item label="邮箱" prop="email">
							<el-input v-model="contactForm.email" disabled></el-input>
						</el-form-item>
					</el-col>
					<el-col :span="11">
						<el-form-item label="电话" prop="mobile">
							<el-input v-model="contactForm.mobile"></el-input>
						</el-form-item>
					</el-col>
				</el-row>
				<el-row>
					<el-col :span='22'>
						<el-form-item label="内容" prop="note">
							<el-input type="textarea" :autosize="{ minRows: 4, maxRows: 4}" placeholder="请输入内容"
								v-model="contactForm.note">
							</el-input>
						</el-form-item>
					</el-col>
				</el-row>
				<el-row>
					<el-col :span='22'>
						<el-form-item style="text-align: center">
							<el-button type="primary" @click="submitForm('contactFormRef')"
								:disabled="buttonControl.isDisable">
								<span v-if="buttonControl.timeCountDown === 0">提交</span>
								<span v-else>({{buttonControl.timeCountDown}}) 秒后可以重新提交</span>
							</el-button>
							<el-button @click="resetForm('contactFormRef')">重置</el-button>
						</el-form-item>
					</el-col>
				</el-row>
			</el-form>
		</el-card>
	</div>
</template>

<script>
import axios from 'axios';
import {mapActions, mapMutations, mapState} from 'vuex';
export default {
	name: 'Contact',
	data() {
		return {
			contactForm: {
				fullname: window.sessionStorage.getItem('username'),
				email: window.sessionStorage.getItem('email'),
				mobile: '',
				note: '',
			},
			contactFormRules: {
				fullname: [
					{required: true, message: '请输入用户名称', trigger: 'blur'},
				],
				email: [
					{required: true, message: '请输入邮箱', trigger: 'blur'},
					{type: 'email', message: '请输入正确的邮箱地址', trigger: ['blur']}
				],
				mobile: [
					{required: true, message: '请输入电话', trigger: 'blur'},
				],
				note: [
					{required: true, message: '请输入内容', trigger: 'blur'}
				]
			},
		};
	},
	computed: {
		...mapState(['buttonControl'])
	},
	methods: {
		...mapActions(['setButtonControlValue']),
		...mapMutations(['changeButtonControl']),
		submitForm(formName) {
			this.$refs[formName].validate(async valid => {
				if (!valid) return;
				this.changeButtonControl({isDisable: true});
				const {data: res} = await axios.post('https://services.wisharemedia.com/wisharemedia/api/gigiclient', this.contactForm);
				if (res.code === 0) {
					this.$notify.success({
						title: '谢谢！',
						message: '您的消息已经发送成功！',
					});
					this.resetForm(formName);
				} else {
					this.$notify.error({
						title: '对不起！',
						message: '消息发送失败，请通过其他方式联系我们！'
					});
					this.resetForm(formName);
				}
				this.changeButtonControl({timeCountDown: 30});
				this.setButtonControlValue();
			});
		},
		resetForm(formName) {
			this.$refs[formName].resetFields();
		}
	}
}
</script>

<style lang="less" scoped>
.el-card {
	.image-wrap {
		text-align: center;
	}
	ul {
		width: 415px;
		margin: 20px auto 0;
		padding-left: 105px;
		li {
			list-style-type: none;
			font-size: 16px;
			line-height: 27px;
			& + li {
				margin-top: 15px;
			}
		}
	}
	p {
		text-align: center;
		font-size: 22px;
		line-height: 1.4;
		font-weight: 700;
		margin-bottom: 40px;
	}
	.el-button + .el-button {
		margin-left: 50px;
	}
}
</style>